/**
 * Checks whether running on a mobile device according to user-agent data.
 * @example: isMobile.android() => true/false
 */
define('isMobileDevice', function() {
  
  var isMobileDevice = {
    getUserAgent: function() {
      return navigator.userAgent;
    },
    android: function(userAgent) {
      return /Android/i.test(userAgent);
    },
    blackberry: function(userAgent) {
      return /BlackBerry|BB10|PlayBook/i.test(userAgent);
    },
    iphone: function(userAgent) {
      return /iPhone/i.test(userAgent) && !isMobileDevice.ipad(userAgent)
        && !isMobileDevice.windows(userAgent);
    },
    ipod: function(userAgent) {
      return /iPod/i.test(userAgent);
    },
    ipad: function(userAgent) {
      return /iPad/i.test(userAgent);
    },
    ios: function(userAgent) {
      return (isMobileDevice.ipad(userAgent) || isMobileDevice.ipod(userAgent)
        || isMobileDevice.iphone(userAgent));
    },
    opera: function(userAgent) {
      return /Opera Mini/i.test(userAgent);
    },
    windows: function(userAgent) {
      return /Windows Phone|IEMobile|WPDesktop/i.test(userAgent);
    },
    kindleFire: function(userAgent) {
      return /Kindle Fire|Silk|KFAPWA|KFSOWI|KFJWA|KFJWI|KFAPWI|KFAPWI|KFOT|KFTT|KFTHWI|KFTHWA|KFASWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFARWI/i.test(
        userAgent);
    },
    any: function(userAgent) {
      return (isMobileDevice.android(userAgent) || isMobileDevice.blackberry(userAgent)
        || isMobileDevice.ios(userAgent)
        || isMobileDevice.opera(userAgent) || isMobileDevice.windows(userAgent));
    }
  };

  return isMobileDevice;
});
